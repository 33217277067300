import React from "react"
import * as styles from "./Benefits.module.css"
import benefitsDB from "econify/data/benefitsdb.json"
import Micromark from "../../../components/Micromark"
import { withPrefix } from "gatsby"

const Benefits = (): JSX.Element => (
  <div className={styles.section}>
    <h2>Benefits</h2>
    <hr />
    <div className={styles.benefitsContainer}>
      {benefitsDB.map((benefit: { icon: string; text: string }) => (
        <div className={styles.benefitsItem} key={benefit.text}>
          <img
            alt={"Benefit Icon"}
            src={withPrefix(`/images/benefits/${benefit.icon}.svg`)}
            className={styles.image}
          />
          <Micromark as="p" markdown={benefit.text} />
        </div>
      ))}
    </div>
  </div>
)

export default Benefits
