import React from "react"
import Micromark from "../../../components/Micromark"
import * as styles from "./Positions.module.css"
import { Link } from "gatsby"

const Positions = ({ positions }): JSX.Element => (
  <div className={styles.section}>
    <h2>Open Positions </h2>
    <hr />
    <div className={`${styles.listHeader}`}>
      <div>ROLE</div>
      <div>LOCATION</div>
    </div>

    {positions.allCareersJson.edges.map(({ node }) => {
      let location
      if (node.locations.length > 1) {
        location = "Multiple"
      } else {
        location = node.locations[0]
      }
      return (
        <Link to={`/careers/${node.parent.name}`} key={node.id}>
          <div className={styles.listItem}>
            <Micromark markdown={node.role} />
            <Micromark markdown={location} />
          </div>
        </Link>
      )
    })}
    <div className={styles.line} />
  </div>
)

export default Positions
