import React, { useEffect, useState } from 'react';

import { Link, withPrefix } from "gatsby"

import menu from "econify/data/header/menu.json"

const hamburgerMenuLineClass = 'w-[30px] h-[4px] bg-black m-[5px]';

interface NavbarProps {
  className?: string;
}

/**
 * Header navbar component
 *
 * @param props.className - any class names to append to the root component
 */
export const Header = ({ className }: NavbarProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { data: menuItems } = menu as {
    data: {
      text: string;
      to: string;
    }[];
  };

  const toggleMobileNavbar = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setMobileMenuOpen(!mobileMenuOpen);
    document.body.style.overflow = mobileMenuOpen ? '' : 'hidden';
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 880 && mobileMenuOpen) {
        setMobileMenuOpen(false);
      }
    };
    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, [mobileMenuOpen]);

  return (
    <header className={`w-full lg:mb-10 lg:pb-10 ${className || ''}`}>
      <div
        className={`max-lg:max-w-[90%] flex flex-wrap justify-between items-center py-5 lg:py-10 lg:px-16 m-auto gap-4 ${
          mobileMenuOpen ? 'h-[100vh]' : 'max-w-[1440px]'
        }`}
      >
        <Link href="/" aria-label="top of home page">
          <div className="inline-flex items-center">
            <img
              className="max-container:max-w-[150px] w-[246px]"
              alt="The famed Econify logo"
              src={withPrefix('/images/econify_logo_v3.svg')}
            />
          </div>
        </Link>
        <div className="block lg:hidden">
          <a
            className="ml-auto cursor-pointer"
            aria-label="Main menu toggle"
            aria-controls="nav-menu"
            aria-expanded={mobileMenuOpen}
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              toggleMobileNavbar(e);
            }}
          >
            {(mobileMenuOpen && (
              <svg
                width="28"
                height="28"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto"
              >
                <line
                  x1="1"
                  y1="26"
                  x2="26"
                  y2="1"
                  stroke="black"
                  stroke-width="4"
                />
                <line
                  x1="1"
                  y1="1"
                  x2="26"
                  y2="26"
                  stroke="black"
                  stroke-width="4"
                />
              </svg>
            )) || (
              <>
                <div className={hamburgerMenuLineClass} />
                <div className={hamburgerMenuLineClass} />
                <div className={hamburgerMenuLineClass} />
              </>
            )}
          </a>
        </div>
        <nav
          className={
            mobileMenuOpen
              ? 'w-full h-full flex text-center flex-col items-center gap-2 list-none uppercase text-3xl leading-loose tracking-[2px] font-gtp font-bold p-0'
              : 'max-lg:hidden list-none text-right tracking-[1px] float-right p-0 flex h-[30px] items-center justify-end gap-[32px] flex-[1_0_0] text-darkGrey text-[22px] font-bold font-gtp'
          }
        >
          {menuItems.map((mi) => (
            <Link
              key={mi.to}
              href={mi.to}
              onClick={() => mobileMenuOpen && setMobileMenuOpen(false)}
            >
              {mi.text}
            </Link>
          ))}
          <Link
            className="bg-[#201b1c] flex h-[64px] py-[20px] px-[1rem] justify-center items-center gap-[10px] shrink-0 uppercase text-white visited:text-white text-[22px] font-gtp font-bold min-w-[172px]"
            href="/start"
            onClick={() => mobileMenuOpen && setMobileMenuOpen(false)}
          >
            Get Started
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
