import React from "react"
import * as styles from "./Values.module.css"
import teamPhoto from "../../../images/Econify-photo.jpg"

const Values = (): JSX.Element => {
  const values = [
    "Integrity",
    "Excellence",
    "Teamwork",
    "Initiative",
    "Curiosity",
  ]
  return (
    <div className={styles.section}>
      <h2>What We Value</h2>
      <hr />
      <div className={styles.valuesContainer}>
        <ul className={styles.valuesList}>
          {values.map((text: string, idx) => (
            <li className={styles.valuesItem} key={idx}>
              {text}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.line} />
    </div>
  )
}

export default Values
