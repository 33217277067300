import React from "react"
import { withPrefix } from "gatsby"

import * as styles from "./Footer.module.css"

const Footer: React.FC = () => {
  return (
    <footer className={styles.container}>
      <hr className={styles.divider} />
      <div className={styles.content}>
        <div>
          <img
            width={150}
            height={60}
            alt={"Econify"}
            src={withPrefix("/images/econify_logo_v3.svg")}
          />
        </div>
        <div className={styles.navigation}>
          <div className={styles.section}>
            <h3 className={styles.heading}>About</h3>
            <ul>
              <li>
                <a href="/#services">Services</a>
              </li>
              <li>
                <a href="/work">Work</a>
              </li>
              <li>
                <a href="/#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="/#clients">Clients</a>
              </li>
            </ul>
          </div>
          <div className={styles.section}>
            <h3 className={styles.heading}>Resources</h3>
            <ul>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/theloop">Newsletter</a>
              </li>
              <li>
                <a href="/performance">Econiscore</a>
              </li>
            </ul>
          </div>
          <div className={styles.spacer} />
          <div className={`${styles.section} ${styles.rightSection}`}>
            <h3 className={styles.heading}>Contact</h3>
            <ul>
              <li>
                <a href="mailto:hello@econify.com">hello@econify.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
