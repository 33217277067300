import React from "react"
import Header from "../components/Header"
import * as styles from "../careers.module.css"
import { graphql, withPrefix } from "gatsby"
import Values from "../routes/Careers/Values"
import Benefits from "../routes/Careers/Benefits"
import Positions from "../routes/Careers/Positions"
import Footer from "econify/components/Footer"

const CareersPage = ({ data }) => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1 className={styles.title}>
          <span style={{ whiteSpace: "pre" }}>Careers</span>
        </h1>
        <hr className={[styles.hr, styles.fadeInLeft].join(" ")} />
        <p className={styles.tag}>
          We're hiring! Join our team of awesome engineers building with the
          best tech
        </p>

        <img
          alt=""
          className={styles.arrowLine}
          src={withPrefix("/images/line_arrow_b.svg")}
          id="clients"
        />
        <Positions positions={data} />
        <Values />
        <Benefits />
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  {
    allCareersJson {
      edges {
        node {
          id
          role
          locations
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`

export default CareersPage
